import React from 'react'
import { _ } from './lib/locale'
import { groupService } from './groups/services'
import * as R from 'ramda'

const AttachmentEditScreen = React.lazy(() => import('./attachments/screens/AttachmentEditScreen'))
const AttachmentListScreen = React.lazy(() => import('./attachments/screens/AttachmentListScreen'))
const BeaconEditScreen = React.lazy(() => import('./beacons/screens/BeaconEditScreen'))
const BeaconListScreen = React.lazy(() => import('./beacons/screens/BeaconListScreen'))
const ContentEditScreen = React.lazy(() => import('./contents/screens/ContentEditScreen'))
const ContentListScreen = React.lazy(() => import('./contents/screens/ContentListScreen'))
const CouponEditScreen = React.lazy(() => import('./coupons/screens/CouponEditScreen'))
const CouponListScreen = React.lazy(() => import('./coupons/screens/CouponListScreen'))
const EmailEditScreen = React.lazy(() => import('./emails/screens/EmailEditScreen'))
const EmailListScreen = React.lazy(() => import('./emails/screens/EmailListScreen'))
const EventEditScreen = React.lazy(() => import('./events/screens/EventEditScreen'))
const EventListScreen = React.lazy(() => import('./events/screens/EventListScreen'))
const GroupEditScreen = React.lazy(() => import('./groups/screens/GroupEditScreen'))
const GroupListScreen = React.lazy(() => import('./groups/screens/GroupListScreen'))
const HeatmapScreen = React.lazy(() => import('./stats/screens/HeatmapScreen'))
const HomeScreen = React.lazy(() => import('./home/screens/HomeScreen'))
const InformationEditScreen = React.lazy(() => import('./informations/screens/InformationEditScreen'))
const InformationListScreen = React.lazy(() => import('./informations/screens/InformationListScreen'))
const NewsEditScreen = React.lazy(() => import('./news/screens/NewsEditScreen'))
const NewsListScreen = React.lazy(() => import('./news/screens/NewsListScreen'))
const OpeningHoursEditScreen = React.lazy(() => import('./openingHours/screens/OpeningHoursEditScreen'))
const OpeningHoursListScreen = React.lazy(() => import('./openingHours/screens/OpeningHoursListScreen'))
const PageEditScreen = React.lazy(() => import('./pages/screens/PageEditScreen'))
const PageListScreen = React.lazy(() => import('./pages/screens/PageListScreen'))
const PathEditScreen = React.lazy(() => import('./paths/screens/PathEditScreen'))
const PathListScreen = React.lazy(() => import('./paths/screens/PathListScreen'))
const QuizEditScreen = React.lazy(() => import('./quizes/screens/QuizEditScreen'))
const QuizListScreen = React.lazy(() => import('./quizes/screens/QuizListScreen'))
const RatingEditScreen = React.lazy(() => import('./ratings/screens/RatingEditScreen'))
const RatingListScreen = React.lazy(() => import('./ratings/screens/RatingListScreen'))
const StatsScreen = React.lazy(() => import('./stats/screens/StatsScreen'))
const StatsDetailScreen = React.lazy(() => import('./stats/screens/StatsDetailScreen'))
const StatsCouponScreen = React.lazy(() => import('./stats/screens/StatsCouponScreen'))
const StatsPushScreen = React.lazy(() => import('./stats/screens/StatsPushScreen'))
const StatsQuizScreen = React.lazy(() => import('./stats/screens/StatsQuizScreen'))
const StatsRatingScreen = React.lazy(() => import('./stats/screens/StatsRatingScreen'))
const StatsTotalUsersScreen = React.lazy(() => import('./stats/screens/StatsTotalUsersScreen'))
const StatsTourScreen = React.lazy(() => import('./stats/screens/StatsTourScreen'))
const StatsTreasureHuntScreen = React.lazy(() => import('./stats/screens/StatsTreasureHuntScreen'))
const TourEditScreen = React.lazy(() => import('./tours/screens/TourEditScreen'))
const TourListScreen = React.lazy(() => import('./tours/screens/TourListScreen'))
const TranslationsEditScreen = React.lazy(() => import('./translations/screens/TranslationEditScreen'))
const TranslationsListScreen = React.lazy(() => import('./translations/screens/TranslationListScreen'))
const TreasureHuntEditScreen = React.lazy(() => import('./treasureHunts/screens/TreasureHuntEditScreen'))
const TreasureHuntListScreen = React.lazy(() => import('./treasureHunts/screens/TreasureHuntListScreen'))
const UserEditScreen = React.lazy(() => import('./users/screens/UserEditScreen'))
const UserListScreen = React.lazy(() => import('./users/screens/UserListScreen'))
const ZoneEditScreen = React.lazy(() => import('./zones/screens/ZoneEditScreen'))
const ZoneListScreen = React.lazy(() => import('./zones/screens/ZoneListScreen'))

const modules = {
  'attachment': [
    { path: '/attachment', exact: true, name: _('Attachment'), component: AttachmentListScreen },
    { path: '/attachment/:id/edit', name: _('Attachment edit'), component: AttachmentEditScreen },
    { path: '/attachment/new', new: true, name: _('New attachment'), component: AttachmentEditScreen }
  ],
  'beacon': [
    { path: '/beacons', exact: true, name: _('Beacons'), component: BeaconListScreen },
    { path: '/beacons/:id/edit', name: _('Beacon edit'), component: BeaconEditScreen },
    { path: '/beacons/new', new: true, name: _('New beacon'), component: BeaconEditScreen }
  ],
  'content': [
    { path: '/contents', exact: true, name: _('Contents'), component: ContentListScreen },
    { path: '/contents/:id/edit', name: _('Content edit'), component: ContentEditScreen },
    { path: '/contents/new', new: true, name: _('New content'), component: ContentEditScreen }
  ],
  'coupons': [
    { path: '/coupons', exact: true, name: _('Coupons'), component: CouponListScreen },
    { path: '/coupons/:id/edit', name: _('Content edit'), component: CouponEditScreen },
    { path: '/coupons/new', new: true, name: _('New content'), component: CouponEditScreen }
  ],
  'email': [
    { path: '/emails', exact: true, name: _('E-mails'), component: EmailListScreen },
    { path: '/emails/:id/edit', name: _('E-mail edit'), component: EmailEditScreen },
    { path: '/emails/new', new: true, name: _('New e-mail'), component: EmailEditScreen }
  ],
  'event': [
    { path: '/events', exact: true, name: _('Events'), component: EventListScreen },
    { path: '/events/:id/edit', name: _('Event edit'), component: EventEditScreen },
    { path: '/events/new', new: true, name: _('New event'), component: EventEditScreen }
  ],
  'group': [
    { path: '/groups', exact: true, name: _('Groups'), component: GroupListScreen },
    { path: '/groups/:id/edit', name: _('Groups edit'), component: GroupEditScreen },
    { path: '/groups/new', new: true, name: _('New group'), component: GroupEditScreen }
  ],
  'heatmap': [
    { path: '/heatmap', exact: true, name: _('Heatmap'), component: HeatmapScreen }
  ],
  'informations': [
    { path: '/informations', exact: true, name: _('Useful informations'), component: InformationListScreen },
    { path: '/informations/:id/edit', name: _('Information edit'), component: InformationEditScreen },
    { path: '/informations/new', new: true, name: _('New information'), component: InformationEditScreen }
  ],
  'news': [
    { path: '/news', exact: true, name: _('News'), component: NewsListScreen },
    { path: '/news/:id/edit', name: _('News edit'), component: NewsEditScreen },
    { path: '/news/new', new: true, name: _('New news'), component: NewsEditScreen }
  ],
  'openinghours': [
    { path: '/openinghours', exact: true, name: _('Opening hours'), component: OpeningHoursListScreen },
    { path: '/openinghours/:id/edit', exact: true, name: _('Opening hours edit'), component: OpeningHoursEditScreen },
    { path: '/openinghours/new', exact: true, name: _('New opening hours'), component: OpeningHoursEditScreen }
  ],
  'pages': [
    { path: '/pages', exact: true, name: _('Pages'), component: PageListScreen },
    { path: '/pages/:id/edit', name: _('Page edit'), component: PageEditScreen },
    { path: '/pages/new', new: true, name: _('New page'), component: PageEditScreen }
  ],
  'path': [
    { path: '/paths', exact: true, name: _('Path'), component: PathListScreen },
    { path: '/paths/:id/edit', name: _('Path edit'), component: PathEditScreen },
    { path: '/paths/new', new: true, name: _('New path'), component: PathEditScreen }
  ],
  'quiz': [
    { path: '/quiz', exact: true, name: _('Quiz'), component: QuizListScreen },
    { path: '/quiz/:id/edit', name: _('Quiz edit'), component: QuizEditScreen },
    { path: '/quiz/new', new: true, name: _('New quiz'), component: QuizEditScreen }
  ],
  'rating': [
    { path: '/ratings', exact: true, name: _('Rating'), component: RatingListScreen },
    { path: '/ratings/:id/edit', name: _('Rating edit'), component: RatingEditScreen },
    { path: '/ratings/new', new: true, name: _('New rating'), component: RatingEditScreen }
  ],
  'stat': [
    { path: '/stats', exact: true, name: _('Statistics'), component: StatsScreen }
  ],
  'stats-detail': [
    { path: '/stats-detail', exact: true, name: _('Detail Statistics'), component: StatsDetailScreen },
    { path: '/stats-coupon', exact: true, name: _('Coupon Statistics'), component: StatsCouponScreen },
    { path: '/stats-push', exact: true, name: _('Push Statistics'), component: StatsPushScreen },
    { path: '/stats-quiz', exact: true, name: _('Quiz Statistics'), component: StatsQuizScreen },
    { path: '/stats-rating', exact: true, name: _('Rating Statistics'), component: StatsRatingScreen },
    { path: '/stats-total-users', exact: true, name: _('Total User Statistics'), component: StatsTotalUsersScreen },
    { path: '/stats-tour', exact: true, name: _('Tour Statistics'), component: StatsTourScreen },
    { path: '/stats-treasurehunt', exact: true, name: _('Treasure Hunt Statistics'), component: StatsTreasureHuntScreen }
  ],
  'tour': [
    { path: '/tours', exact: true, name: _('Tours'), component: TourListScreen },
    { path: '/tours/:id/edit', name: _('Tour edit'), component: TourEditScreen },
    { path: '/tours/new', new: true, name: _('New tour'), component: TourEditScreen }
  ],
  'translations': [
    { path: '/translations', exact: true, name: _('Translations'), component: TranslationsListScreen },
    { path: '/translations/:id/edit', name: _('Translations edit'), component: TranslationsEditScreen },
    { path: '/translations/new', new: true, name: _('New translations'), component: TranslationsEditScreen }
  ],
  'treasure-hunt': [
    { path: '/treasure-hunt', exact: true, name: _('Treasure-hunt'), component: TreasureHuntListScreen },
    { path: '/treasure-hunt/:id/edit', name: _('Treasure-hunt edit'), component: TreasureHuntEditScreen },
    { path: '/treasure-hunt/new', new: true, name: _('New treasure-hunt'), component: TreasureHuntEditScreen }
  ],
  'user': [
    { path: '/users', exact: true, name: _('Users'), component: UserListScreen },
    { path: '/users/:id/edit', name: _('User edit'), component: UserEditScreen },
    { path: '/users/new', new: true, name: _('New user'), component: UserEditScreen }
  ],
  'zone': [
    { path: '/zones', exact: true, name: _('Zone'), component: ZoneListScreen },
    { path: '/zones/:id/edit', name: _('Zone edit'), component: ZoneEditScreen },
    { path: '/zones/new', new: true, name: _('New zone'), component: ZoneEditScreen }
  ]
}

const routes = [
  { path: '/', exact: true, name: _('Home'), component: HomeScreen }
]

export function getRoutesByRole (role) {
  const group = groupService.getGroupByRole(role)

  return group && R.keys(group.data.modules).length > 0
    ? [
      { path: '/', exact: true, name: _('Home'), component: HomeScreen },
      ...R.keys(group.data.modules).filter((x) => (group.data.modules[x]))
        .reduce((s, x, i) => ([
          ...s,
          ...modules[x]
        ]), [])
    ]
    : routes
}

export default routes
