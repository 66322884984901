const translations = `
en,hu
Zones,Zónák
Delete,Törlés
Logout,Kijelentkezés
Edit document,Dokumentum szerkesztése
Save,Mentés
Heatmap,Hőtérkép
Groundfloor,Földszint
First Floor,Emelet
Gallery,Karzat
The end date cannot occure later than the start date!,A végső dátum nem lehet időrendben később mint a kezdeti dátum!
Map,Térkép
Date,Dátum
Interval,Időszak
Real Time,Valós idő
Start date,Kezdő dátum
End date,Végső dátum
Change,Módosítás
Drag and drop some files here or click to select files,Húzzon ide egy fájlt vagy kattintson a fájlböngészőért
Add new,Új hozzáadása
Home,Kezdőlap
Title,Cím
Tours,Túrák
Tour edit,Túra szerkesztése
New tour,Új túra
Attachment,Melléklet
Attachment edit,Melléklet szerkesztése
New attachment,Új melléklet
Events,Események
Event edit,Esemény szerkesztése
New event,Új esemény
Groups,Csoportok
Group edit,Csoport szerkesztése
New group,Új csoport
Users,Felhasználók
User edit,Felhasználó szerkesztése
New user,Új felhasználó
E-mails,E-mailek
E-mail edit,E-mail szerkesztése
New e-mail,Új e-mail
Beacons,Jeladók
Beacon edit,Jeladó szerkesztése
New beacon,Új jeladó
Contents,Tartalmak
Content edit,Tartalmak szerkesztése
New content,Új tartalom
News,Hírek
News edit,Hír szerkesztése
New news,Új hír
Opening hours,Nyitvatartási idők
Opening hours edit,Nyitvatartási idők szerkesztése
New opening hours,Új nyitvatartási idők 
Translations,Fordítások
Translations edit,Fordítások szerkesztése
New translations,Új fordítások
Treasure-hunt,Kincsvadászat
Treasure-hunt edit,Kincsvadászat szerkesztése
New treasure-hunt,Új kincsvadászat
Quiz,Kvíz
Quizes,Kvízek
Quiz edit,Kvíz szerkesztése
New quiz,Új kvíz
Rating edit,Értékelés szerkesztése
Rating,Értékelés
Ratings,Értékelések
New rating,Új értékelés
Subtitle,Alcím
Path,Útvonal
Paths,Útvonalak
Path edit,Útvonal szerkesztése
New path,Új útvonal
Creation date,Létrehozás dátuma
Statistics,Statisztikák
Detail Statistics,Részletes Statisztikák
Zone,Zóna
Zone edit,Zóna szerkesztése
New zone,Új zóna
Test,Teszt
Title,Cím
Choose an option from the sidebar,Válaszon egy opciót az oldalsávból
Done,Kész
Cancel,Mégsem
Reload to apply changes?,Újratölti a beállítások érvényesítéséhez?
Active users total for completed months,Aktív felhasználók száma az utolsó 12 hónapban
Active users running for current month,Aktív felhasználók az aktuális hónapban
All time total user,Az eddigi összes felhasználók száma
Total zone visits monthly,Összes zóna látogatás havonta
Total zone visits per zone,Összes zóna látogatás zónánként
Dwell time (minutes) by day of week,Tartózkodási idő (percben) a hét napjai szerint
January,Január
February,Február
March,Március
April,Április
May,Május
June,Június
July,Július
August,Augusztus
September,Szeptember
October,Oktober
November,November
December,December
Monday,Hétfő
Tuesday,Kedd
Wednesday,Szerda
Thursday,Csütörtök
Friday,Péntek
Saturday,Szombat
Sunday,Vasárnap
Months,Hónapok
Visitors,Látogatók
Days,Napok
Days of week,A hét napjai
Days of current month,A hónap napjai
dwell time,eltöltött idő
dow,hn
Today,Ma
There is no data with these filter parameters,Nincs adat ezekkel a szűrési paraméterekkel
Real-time Heatmap,Valós idejű hőtérkép
Welcome on the Matthias Church mobil application admin page!,Köszöntjük a Mátyás-templom mobil alkalmazás adminisztrációs felületén!
Please select a menu on the left!,Kérjük válasszon a bal oldali menüpontokból!
Name,Név
Role,Szerep
Type,Típus
Rank,Sorszám
State,Állapot
Date of release,Kiadás dátuma
Date of use,Felhasználás dátuma
Used,Felhasznált
Heatmaps,Hőtérképek
Visit data,Látogatási adatok
Quiz,Kvíz
Ratings,Értékelések
Push notifications,Push értesítések
Tours,Túrák
Treasure Hunt,Kincskereső
Counpons,Kuponok
Ratio of questions asked/answered per question,Kérdésenként a feltett kérdés/megválaszolt kérdés aránya
The proportion of correct/incorrect answers per question,Kérdésenként a helyes/helytelen válaszok aránya
Ratio of questions asked/answered per question,Kérdésenként a feltett kérdés/megválaszolt kérdés aránya
Value of ratings per question,A minősítések értéke kérdésenként
The number of pushes sent out,Kimenő pushok száma
Proportion of pushes opened,Megnyitott pushok száma
Number of tour participants by month,Túrán résztvevők száma havonta
Tours taken per month,Túrák száma havonta
Tours statistics,Túra statisztikák
Number of official tours,Hivatalos túrák száma
Number of game starts,Megkezdett játékok száma
Number of users who finished all games,Végigjátszások száma
Number of gamers,Játékosok száma
Ratio of questions asked/answered per question,Kérdésenként a feltett kérdés/megválaszolt kérdés aránya
Showed,Megjelenített
Answered,Megválaszolt
Correct,Helyes
Wrong,Hibás
Answered correctly,Helyesen megválaszolt
Answered wrongly,Hibásan megválaszolt
Rated,Értékelt
Average rating (1 to 5),Átlagos értékelés (1-től 5-ig)
Number of players,Játékosok száma
Total answered,Összes megválaszolt
Pushes sent out,Kimenő pushok száma
Pushes opened,Megnyitott pushok
Showed and answered,Megjelenített és megválaszolt
Showed and not answered,Megjelenített és meg nem válaszolt
Answered and correct,Megválaszolt és helyes
Answered and not correct,Megválaszolt és nem helyes
Showed and correct,Megjelenített és helyes
Showed and not correct,Megjelenített és nem helyes
Dwell time (minutes),Tartózkodási idő (perc)
Pages,Oldalak
Page edit,Oldal szerkesztése
New page,Új oldal
default,alapbeállítás
Useful informations,Hasznos információk
Information edit,Információ szerkesztése
New information,Új információ
Visibility type,Láthatóság típusa
published,publikált
draft,vázlat
POI,Érdekes pont
Image map,Kép alapú térkép
GPS map,GPS alapú térkép
Container,Konténer
Select from options,Kiválasztom a lehetőségek közül
Status,Státusz
Active,Aktív
Inactive,Inaktív
Rank,Sorszám
+ Add new,+ Új hozzáadása
Are you sure?,Biztos vagy benne?
Start degree,Kezdő fok
End degree,Végső fok
id,id
email,email
Coupons,Kuponok
Coupon edit,Kupon szerkesztése
New coupon,Új kupon
Please try another name because this beacon name has already been used!,Kérjük próbáljon más jeladó nevet megadni mivel a a jelenlegi név már foglalt.
"The zone cannot be deleted because the following zones depend on their existence","Nem törölhető a zóna, mivel a következő zónák függnek a meglététől"
Value,Érték
Limit,Limit
Number of usage,Felhasználások száma
Value of used coupons,Felhasználások összértéke
Maximum number of participants,Résztvevők maximális száma
Minimum number of participants,Résztvevők minimális száma
Average number of participants,Résztvevők átlagos száma
All,Összes
Number of game starts,Játékkezdések száma
Number of finishes,Összes állomás teljesítésének száma
Push notification has to be translated to every system language!,A push értesítést le kell fordítani a rendszer minden nyelvére!
No map is loaded,Nincs térkép betöltve
Export,Exportálás
Select a month,Válassz egy hónapot
January,Január
February,Február
March,Március
April,Április
May,Május
June,Június
July,Július
August,Augusztus
September,Szeptember
October,Október
November,November
December,December
Released,Kiadott
Used,Felhasznált
Type the filtering text,Írd be a szűrt szöveget
Column to filter,Szűrés oszlopa
`

export default translations
