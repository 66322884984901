import autobind from 'autobind-decorator'
import { Screen } from '../../common/screens'
import { LoginLayout } from '../components'
import { withAuth } from '../context/authContext'
import { LoadingIndicator, ErrorIndicator } from '../../common/components'

class LoginScreen extends Screen {
  state = {
    loading: false,
    error: null
  }

  @autobind
  async handleSubmit (params) {
    const { auth } = this.props

    this.setState({ loading: true, error: null })
    try {
      await auth.login(params)
      this.setState({ loading: false })
    } catch (e) {
      this.setState({ loading: false, error: e })
    }
  }
  renderScreen () {
    const { loading, error } = this.state

    if (error) {
      return <ErrorIndicator error={error} onRetry={() => { document.location.href = '/' }} />
    }

    if (loading) {
      return <LoadingIndicator />
    }

    return (
      <LoginLayout onSubmit={this.handleSubmit} />
    )
  }
}

export default withAuth(LoginScreen)
