import PropTypes from 'prop-types'
import React, { Component, Suspense } from 'react'
import { Container } from 'reactstrap'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { AppFooter, AppHeader, AppBreadcrumb } from '@coreui/react'

import routes from '../../../routes'

const SideBar = React.lazy(() => import('../SideBar'))
const Footer = React.lazy(() => import('../Footer'))
const Header = React.lazy(() => import('../Header'))

class MainLayout extends Component {
  static propTypes = {
    children: PropTypes.node,
    langs: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        lang: PropTypes.string.isRequired
      })
    ),
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      avatarUrl: PropTypes.string
    })
  }

  loading = () => <div className='animated fadeIn pt-1 text-center'><div className='sk-spinner sk-spinner-pulse' /></div>

  signOut (e) {
    e.preventDefault()
    this.props.history.push('/login')
  }

  render () {
    const { children, user, langs } = this.props

    return (
      <div className='app'>
        <AppHeader fixed>
          <Suspense fallback={this.loading()}>
            <Header user={user} langs={langs} />
          </Suspense>
        </AppHeader>
        <div className='app-body'>
          <Suspense fallback={this.loading()}>
            <SideBar />
          </Suspense>
          <main className='main'>
            <ToastContainer
              position='top-right'
              autoClose={2000}
              style={{ zIndex: 1999 }} />
            <AppBreadcrumb appRoutes={routes} />
            <Container fluid>
              { children }
            </Container>
          </main>
        </div>
        <AppFooter>
          <Suspense fallback={this.loading()}>
            <Footer />
          </Suspense>
        </AppFooter>
      </div>
    )
  }
}

export default MainLayout
