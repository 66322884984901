import React, { Component, Suspense } from 'react'

import {
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav
} from '@coreui/react'

import navigation from '../../../_nav'
import { withAuth } from '../../../auth/context/authContext'
import { getRoutesByRole } from '../../../routes'

class SideBar extends Component {
  render () {
    const { auth } = this.props

    const routePaths = getRoutesByRole(auth.role).map((x) => x.path)
    const userNavigation = {
      items: navigation.items.filter((x) => routePaths.includes(x.url))
    }

    return (
      <React.Fragment>
        <AppSidebar fixed display='lg' style={{ backgroundColor: '#FFCB06' }}>
          <AppSidebarHeader />
          <AppSidebarForm />
          <Suspense>
            <AppSidebarNav navConfig={userNavigation} />
          </Suspense>
          <AppSidebarFooter />
          <AppSidebarMinimizer style={{ backgroundColor: '#454b52 ' }} />
        </AppSidebar>
      </React.Fragment>
    )
  }
}

export default withAuth(SideBar)
