import React, { Component } from 'react'
import uuidv4 from 'uuid/v4'
import { Buffer } from 'buffer'
import sdk from 'locbee-javascript-sdk'
import { contextUtils } from '../../lib'

export const AuthContext = React.createContext()
export const withAuth = contextUtils.createContextHOC(AuthContext, 'auth')

const STORAGE_KEY = 'auth-data'

function getInitialData () {
  return {
    loggedIn: false,
    authToken: null,
    sessionId: uuidv4(),
    role: null
  }
}

function getJWTData (token) {
  const parts = token.split('.')
  if (parts.length !== 3) {
    throw new Error('Invalid JWT.')
  }
  const data = Buffer.from(parts[1], 'base64').toString('ascii')
  return JSON.parse(data)
}

export class AuthProvider extends Component {
  state = {
    data: getInitialData()
  }

  componentDidMount () {
    const dataJson = localStorage.getItem(STORAGE_KEY)
    if (dataJson) {
      const data = JSON.parse(dataJson)
      this.setState({ data })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.data !== this.state.data) {
      if (this.state.data.loggedIn) {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(this.state.data))
        sdk.env['authToken'] = this.state.data.authToken
      } else {
        localStorage.removeItem(STORAGE_KEY)
      }
    }
  }

  render () {
    const { data } = this.state
    const value = {
      ...data,
      login: async ({ email, password }) => {
        const response = await sdk.auth.doBasicAuth({
          email,
          sessionId: data.sessionId,
          password
        })
        const tokenData = getJWTData(response.authToken)
        if (tokenData.email && tokenData.sid) {
          this.setState({
            data: {
              loggedIn: true,
              authToken: response.authToken,
              sessionId: tokenData.sid,
              role: tokenData.role
            }
          })
        } else {
          throw new Error('email / sid missing from token')
        }
      },
      logout: () => {
        this.setState({
          data: getInitialData()
        })
      }
    }

    return (
      <AuthContext.Provider value={value}>
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}
