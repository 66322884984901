import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import styles from './PopoverItem.module.scss'
import { Button, Popover, PopoverBody, PopoverHeader } from 'reactstrap'
import autobind from 'autobind-decorator'

class PopoverItem extends PureComponent {
  static propTypes = {
    id: PropTypes.number.isRequired,
    placement: PropTypes.string,
    label: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string.isRequired
  }

  static defaultProps = {
    placement: 'top'
  }

  state = {
    popoverOpen: false
  }

  @autobind
  toggle () {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    })
  }

  render () {
    const { id, placement, label, title, description } = this.props
    const { popoverOpen } = this.state

    return (
      <span>
        <Button className={['mr-1', styles.button]} color='info' id={'Popover-' + id} onClick={this.toggle}>
          {label}
        </Button>
        <Popover placement={placement} isOpen={popoverOpen} target={'Popover-' + id} toggle={this.toggle}>
          <PopoverHeader>{title}</PopoverHeader>
          <PopoverBody>{description}</PopoverBody>
        </Popover>
      </span>
    )
  }
}

export default PopoverItem
